$(function() {
  var $win = $(window),
      winW = $(window).width(),
      winH = $(window).height(),
      pos = $(window).scrollTop(),
      resizeTimer = false,
      $header = $('header');

  var topBtn = $('#arrowUp'),
      arrowDown = $('#arrowDown');  

  /////////////////////////// init
  function init(){
    //ローディング画面
    /* $('#wrapper').css('display','none');
    $('#loading ,#loader').height(winH).css('display','block');
    $('#loading').delay(900).fadeOut(800); 
    $('#loader').delay(900).fadeOut(800);
    $('#wrapper').css('display', 'block'); */

    spNav();
    anchorLink ();

    // ページトップのボタン関連
    topBtn.hide(); 
    topBtn.click(function () {
      $('body,html').animate({
        scrollTop: 0
      }, 500);
      return false;
    });

    

  }

  /////////////////////////// ハンバーガーメニュー
  function spNav(){
    $('.c-navigation__button').click(function(){ 
      $('.c-navigation').addClass('active');
    });
    $('.c-navigation__close, a[href^="#"]').click(function(){ 
      $('.c-navigation').removeClass('active');
    });
  }

  /////////////////////////// アンカーリンク（スムーススクロール）
  function anchorLink (){
    $('a[href^="#"]').click(function() {
      var speed = 400; 
      var href= $(this).attr("href");
      var target = $(href == "#" || href == "" ? 'html' : href);
      var position = target.offset().top - 230;
      $('body,html').animate({scrollTop:position}, speed, 'swing');
      return false;
    });
  }

  

  /////////////////////////// ヘッダーの表示関連
  function headerAnime () {
    var animationClass = 'is-animation'
    if ( (winW > 768 && pos > 800 ) || (winW <= 768 && pos > 300) ) {
      $header.addClass(animationClass);
    } else {
      $header.removeClass(animationClass);
    }
  }
  /////////////////////////// ページトップボタン　矢印の表示
  function pageTopBtnDir(){
    if (pos > 500) {
      topBtn.fadeIn();
    } else {
      topBtn.fadeOut();
    }

    if (pos < 500) {
      arrowDown.fadeIn();
    } else {
      arrowDown.fadeOut();
    }
  }

  /////////////////////////// load
  $win.on('load', onLoad)
  function onLoad(){
    onResize();
    onScroll();
    init();
  }
  
  /////////////////////////// resize
  $win.on('resize', onResize)
  function onResize(){
    winW = $(window).width()
    winH = $(window).height()

    
    if (resizeTimer !== false) {
        clearTimeout(resizeTimer);
    }
    resizeTimer = setTimeout(function() {
        
    }, 200);
  }

  /////////////////////////// scroll
  $win.on('scroll', onScroll)
  function onScroll(){
    pos = $(window).scrollTop()

    headerAnime ();
    pageTopBtnDir();
  }

});
